import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FaGithub, FaInstagram } from "react-icons/fa";

const FooterComponent = () => {
  return (
    <div className='footer'>
        <div className="footer_copyright">
            IFertek.it | ©2024
        </div>
        <div className="footer_socials">
            <ul>
                <li>
                    <Link about='_blank' to={'https://www.instagram.com/__i_fer__/'} target="_blank" ><FaInstagram size={22}/></Link>
                </li>
                <li>
                    <Link about='_blank' to={'https://github.com/imash05'} target="_blank" ><FaGithub size={22}/></Link>
                </li>
                
            </ul>
        </div>
    </div>
  )
}

export default FooterComponent