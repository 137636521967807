import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import "./index.scss";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Error404 from "./pages/404";
import CapoLavoro from "./pages/progs/capolavoro";
import CookieModal from "./components/CookieModal";
import global_it from "./translations/it/global.json";
import global_en from "./translations/en/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import App from "./App";

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    it: {
      global: global_it,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/projects/capolavoro",
    element: <CapoLavoro />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();

// TODO Puoi fornire una UX decisamente migliore di questa quando la tua app genera errori fornendone una tua ErrorBoundaryO errorElementsostegno sul tuo percorso.
