import React from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const HeaderComponent = () => {
  return (
    <div className="header">
      <ul>
        <HashLink to={"/#about"} smooth>About</HashLink>
        <HashLink to={"/#projects"} smooth>Projects</HashLink>
        <HashLink to={"/#contact"} smooth>contact</HashLink>

        <HashLink to={"/nothing"} smooth>???</HashLink>

        {/* <NavLink to={"/contact"}>Contact</NavLink> */}
      </ul>
    </div>
  );
};

export default HeaderComponent;
