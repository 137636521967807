import React from "react";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import { FaGears } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation("global");

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="app">
      <div className="language_switch">
        <IoLanguage fontSize={25} />
        <button
          onClick={() => handleChangeLang("en")}
          className={i18n.language === "en" ? "active" : ""}
        >
          Eng
        </button>
        <button
          onClick={() => handleChangeLang("it")}
          className={i18n.language === "it" ? "active" : ""}
        >
          Ita
        </button>
      </div>
      <div className="dev">
        <FaGears /> under construction v1.4
      </div>
      <div className="layoutHead">
        <HeaderComponent />
      </div>
      <div className="layoutBody">{children}</div>
      <div className="layoutFooter">
        <FooterComponent />
      </div>
    </div>
  );
};

export default Layout;
