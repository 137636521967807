import React, { useState } from "react";
import Layout from "../../components/Layout";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { TiArrowRightOutline } from "react-icons/ti";
import { gsap, Power3 } from "gsap";
import Capolavoro_first_section from "../../components/capolavoro_first_section";
import { MdOpenInNew } from "react-icons/md";
import CapolavoroFirstSection from "../../components/capolavoro_first_section";
import CustonModal from "../../components/modal/custon_modal";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const CapoLavoro = () => {
  const [progModal, setProgModal] = useState(false);

  const handleGoBack = () => {
    window.history.back();
  };

  const handleOpenProgModal = (project) => {
    setProgModal(true);
  };

  const handleCloseProgModal = () => {
    setProgModal(false);
  };

  return (
    <Layout>
      {progModal && (
        <CustonModal
          onBgClick={handleCloseProgModal}
          closeBtn={handleCloseProgModal}
        >
          {" "}
          dettaglio del 1 section del gallery
        </CustonModal>
      )}
      <div className="progview">
        {/* pulsante per tornare indietro */}
        <button className="progview_backbtn" onClick={handleGoBack}>
          <FaArrowLeft /> Go Back
        </button>

        {/* prima sezioione con lintro e mini descrizione  */}
        <CapolavoroFirstSection />

        {/* galleria con spiegazione generale del progetto  */}
        <h2 className="gallery_tittle">
          Key Features of the Automated Greenhouse Control System
        </h2>
        <div className="gallery">
          <div className="gallery_section">
            <div className="gallery_section_head">
              <span>01.</span>
              <span>
                This feature ensures plants are watered appropriately.
              </span>
              <h2>Automatic Irrigation System</h2>
            </div>

            <div className="gallery_section_details">
              <p>
                The irrigation system ensures that plants receive the right
                amount of water based on real-time soil moisture levels. This
                helps in conserving water and promoting healthy plant growth.
              </p>
              <button onClick={handleOpenProgModal}>
                More Details
                <MdOpenInNew />
              </button>
            </div>
            <img
              src={
                "https://artigianfer.com/wp-content/uploads/2019/02/gestione-idrica.jpg"
              }
              alt=""
            />
          </div>
          <div className="gallery_section">
            <div className="gallery_section_head">
              <span>02.</span>
              <span>
                This feature maintains the ideal climate inside the greenhouse.
              </span>
              <h2>Efficient Ventilation System</h2>
            </div>

            <div className="gallery_section_details">
              <p>
                The ventilation system maintains optimal air temperature and
                humidity within the greenhouse. It automatically activates fans
                and ventilators when the temperature exceeds set thresholds,
                ensuring a stable environment for plant growth.
              </p>
            </div>
            <img
              src="https://cdn11.bigcommerce.com/s-44t8fz8vq4/product_images/uploaded_images/venting-960x.jpg"
              alt=""
            />
          </div>
          <div className="gallery_section">
            <div className="gallery_section_head">
              <span>03.</span>
              <span>This feature provides additional light for plants.</span>
              <h2>Automated UV Lighting</h2>
            </div>

            <div className="gallery_section_details">
              <p>
                The lighting system uses LED grow lights that supplement natural
                sunlight. These lights are automatically adjusted based on
                ambient light levels, ensuring plants receive adequate light for
                photosynthesis throughout the day and night.
              </p>
            </div>
            <img
              src="https://www.carnivorousplants.org/sites/default/files/images/SoilsWaterLight/LED.PlantPleasureRoom_CT.jpg"
              alt=""
            />
          </div>
        </div>
        <h2 className="cards_tittle">
          Key Features of the Automated Greenhouse Control System
        </h2>
        <div className="progview_card_section">
          <div className="right">
            <div className="card big-card">
              <h3>PLC Programming and Ladder Logic</h3>
              <p>
                Describe how the PLC is programmed using Ladder Logic to
                automate irrigation, ventilation, and lighting. Include a brief
                example of the Ladder Logic code used and explain its importance
                in ensuring the system operates smoothly and efficiently.
              </p>
              <div className="bg"></div>
            </div>
          </div>
          <div className="left">
            <div className="card middle-card">
              <h3>Sensor Integration</h3>
              <p>
                Highlight the various sensors used in the greenhouse, such as
                soil moisture sensors, temperature sensors, and light sensors.
                Explain how these sensors collect data and feed it into the PLC
                for real-time monitoring and control.
              </p>
              <div className="bg"></div>
            </div>
            <div className="card small-card">
              <h3>Benefits of Automation</h3>
              <p>
                Summarize the key benefits of the automated greenhouse system,
                such as increased efficiency, optimal plant growth, and resource
                conservation.
              </p>
              <div className="bg"></div>
            </div>
          </div>
        </div>

        <div className="progview_last_section">
          <span>00. Whats next? </span>
          <h3> Get in Touch!</h3>
          <p>
            inbox is always open. Whether you have a question or just want to
            say hi, I’ll try my best to get back to you!
          </p>
          <Link to={"/contact"}>Say Hello</Link>
        </div>
      </div>
    </Layout>
  );
};

export default CapoLavoro;
