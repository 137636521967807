import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap, Power3 } from "gsap";
import { TiArrowRightOutline } from "react-icons/ti";
import { useTranslation } from "react-i18next";

import { FaArrowLeft, FaFileDownload } from "react-icons/fa";

const CapolavoroFirstSection = () => {
  const progetto = [
    {
      "Nome Progetto": "Automatic Control System for a Greenhouse",
      Descrizione:
        "The project consists of implementing an automatic control system for a greenhouse, which regulates irrigation, ventilation and lighting based on parameters such as soil moisture, temperature and ambient light. The system uses a PLC to manage the various sensors and actuators.",
      Tag: ["#in work", "#electrotechnics", "#plc", "#ladder"],
      Immagine: require("../assets/prog/capolavoro.png"),
      projectUrl: "capolavoro",
    },
  ];


  // traduzione
  const { t, i18n } = useTranslation("global");

  return (
    <div className="progview_first_section">
      <img src={progetto[0].Immagine} alt="" />
      <div className="progview_first_section_detail">
        <h1>{t("pages.capolavoro.fs.title")} </h1>
        <p>
        {t("pages.capolavoro.fs.desc")}
        </p>
        <div className="tag_list">
          {["#in work", "#electrotechnics", "#plc", "#ladder"].map(
            (tag, index) => (
              <span className="tag" key={index}>
                {tag}
              </span>
            )
          )}
        </div>
        <Link to="">
          Download PDF <FaFileDownload />
        </Link>
      </div>
    </div>
  );
};

export default CapolavoroFirstSection;
