// src/pages/Contact.js

import React, { useLayoutEffect, useRef } from "react";
import { FaGithub, FaInstagram } from "react-icons/fa";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { gsap, Power3 } from "gsap";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const titleRef = useRef(null);
  const bodyRef = useRef(null);
  const linksRef = useRef([]);

  const animateContact = () => {
    const timeline = gsap.timeline();

    timeline
      .fromTo(
        titleRef.current,
        { opacity: 0, y: "20" },
        { opacity: 1, y: "0", duration: 0.5, ease: Power3.easeOut }
      )
      .fromTo(
        bodyRef.current,
        { opacity: 0, y: "20" },
        { opacity: 1, y: "0", duration: 0.5, ease: Power3.easeOut },
        "-=0.3"
      )
      .fromTo(
        linksRef.current,
        { opacity: 0, y: "20" },
        {
          opacity: 1,
          y: "0",
          duration: 0.5,
          ease: Power3.easeOut,
          stagger: 0.2,
        },
        "-=0.3"
      );
  };

  useLayoutEffect(() => {
    animateContact();
  }, []);

  // traduzione
  const { t, i18n } = useTranslation("global");

  return (
    <>
      <div className="contact" id="contact">
        <h1 ref={titleRef}>{t("pages.contact.title")}</h1>
        <div className="contact_body" ref={bodyRef}>
          <p>{t("pages.contact.paragraph")}</p>
          <div className="contact_info">
            <div
              className="contact_link"
              ref={(el) => (linksRef.current[0] = el)}
            >
              <Link to={"https://www.instagram.com/__i_fer__"}>
                <FaInstagram /> @__i_fer__
              </Link>
            </div>
            <div
              className="contact_link"
              ref={(el) => (linksRef.current[1] = el)}
            >
              <Link to={"mailto:imashfernando05@gmail.com"}>
                <MdEmail /> imashfernando05@gmail.com
              </Link>
            </div>
            <div
              className="contact_link"
              ref={(el) => (linksRef.current[2] = el)}
            >
              <Link to={"https://github.com/imash05"}>
                <FaGithub /> @imash05
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
