import React from "react";
import { FaCircle } from "react-icons/fa";

const CustonModal = ({ children, onBgClick, closeBtn }) => {

  const handleBgClick = (e) => {
    if (e.target.className === 'custom_modal_bg') {
      onBgClick();
    }
  };
  return (
    <div className="custom_modal_bg" onClick={handleBgClick}>
      <div className="custom_modal">
        <button onClick={closeBtn} className="closeBtn">
          <FaCircle />
        </button>
        {children}
      </div>
    </div>
  );
};

export default CustonModal;
