import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap, Power3 } from "gsap";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

const About = () => {
  const spanRef = useRef(null);
  const h1Ref = useRef(null);
  const h2Ref = useRef(null);
  const pRef = useRef(null);
  const linkRef = useRef(null);

  const animateAbout = () => {
    const timeline = gsap.timeline();

    timeline
      .fromTo(
        spanRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: Power3.easeOut }
      )
      .fromTo(
        h1Ref.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: Power3.easeOut },
        "-=0.3"
      )
      .fromTo(
        h2Ref.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: Power3.easeOut },
        "-=0.3"
      )
      .fromTo(
        pRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: Power3.easeOut },
        "-=0.3"
      )
      .fromTo(
        linkRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.5, ease: Power3.easeOut },
        "-=0.3"
      );
  };

  useLayoutEffect(() => {
    animateAbout();
  }, []);

  // traduzione
  const { t, i18n } = useTranslation("global");

  return (
    <div className="about" id="about">
      <div className="about_data">
        <span ref={spanRef}>{t("pages.about.title.greeting")}</span>
        <h1 ref={h1Ref}>Imash Fernando.</h1>
        <h2 ref={h2Ref}>{t("pages.about.title.tagline")}</h2>
        <p ref={pRef}>
          {t("pages.about.paragraph")}
        </p>
        <div ref={linkRef}>
          <HashLink to={"/#projects"} smooth>{t("pages.about.link")}</HashLink>
        </div>
      </div>
    </div>
  );
};

export default About;
