import React from "react";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

const App = () => {
  return (
    <Layout>
      {/* first section  */}
      <About />
      {/*project section */}
      <Projects />
      {/* contact section */}
      <Contact />
    </Layout>
  );
};

export default App;
